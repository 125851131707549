import { Commit } from "vuex";

export const SnackbarStore = {
  state:() => ({
    showSnackbar : false,
    snackbarData: {
      text:[],
      color:''
    }
  }),
  
  getters: {
    shouldShowSnackbar : (state:any) => state.showSnackbar,
    getSnackbar : (state:any) => state.snackbarData
  },
  
  actions : {
    setSnackbar({commit}:{commit:Commit}, payload:any){
      commit('SET_SNACKBAR', payload);
    }
  },
  
  mutations : {
    SET_SNACKBAR(state:any, payload:any){
      state.showSnackbar = true;
      state.snackbarData.color = payload.color
      state.snackbarData.text = payload.text
      setTimeout(() => {
        state.showSnackbar = false
      }, 1000)
    }
  }
}