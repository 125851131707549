<template>
  <v-app>
    <top-bar v-if="shouldShowBars" style="background-color: #13161D;"/>
    <bottom-bar v-if="shouldShowBars" style="background-color: #13161D;"/>
    <tour-card v-if="showTour"/>
    <v-main>
      <router-view/>
    </v-main>
    <div class="text-center ma-2">
      <v-snackbar 
        v-model="showSnackbar" 
        elevation="0"      
        class="mb-16 custom-snackbar" 
        multi-line
      >
        <v-list dense class="custom-list">
          <v-list-item
            v-for="(item, index) in snackbarData.text"
            :key="index"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const TopBar = defineAsyncComponent(() => import("@/components/TopBar.vue"));
const BottomBar = defineAsyncComponent(() => import("@/components/BottomBar.vue"));

const TourCard = defineAsyncComponent(() => import("@/components/TourCard.vue"));

const store = useStore();
const route = useRoute();

const showSnackbar = computed(() => store.getters.shouldShowSnackbar);
const snackbarData = computed(() => store.getters.getSnackbar);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const showTour = computed(() => store.getters.showTutorial && store.getters.canStartTour)

const shouldShowBars = computed(() => isLoggedIn.value && !['/intro', '/login', '/forgot-password','/signup','/warrant-intro'].includes(route.path));
</script>

<style>
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #13161D;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #13161D;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.white-btn {
  font-weight:300;
  color:white !important;
  background: rgb(120,210,157);
  background: linear-gradient(11deg, rgba(120,210,157,1) 28%, rgba(75,160,102,1) 55%);  
  text-transform: unset !important;
}

/* Sélecteur plus précis (v-list.custom-list à l’intérieur d’un conteneur .custom-snackbar) */
.custom-snackbar .v-list.custom-list {
  margin: 0 auto;             /* Centrage horizontal si c'est un bloc */
  border-radius: 10px;
  text-align: center;         /* Centre le texte à l'intérieur */
  display: flex;             /* Transforme le conteneur en flexbox */
  flex-direction: column;    /* Les items sont en colonne */
  justify-content: center;   /* Centre le contenu verticalement s’il y a de la place */
  align-items: center;       /* Centre le contenu horizontalement au sein de la flexbox */
  /* Largeur minimale de 200px */
  min-width: 200px;
  
  /* Permet la croissance selon le contenu,
     au lieu de forcer une largeur fixe. */
  width: auto;

  /* (Optionnel) limiter la largeur maxi si le texte est très long. */
  max-width: 600px;

  /* Pour centrer le bloc dans le conteneur parent */
  margin: 0 auto;

  /* Centrer le texte */
  text-align: center;

  /* Faire en sorte que le bloc ne prenne que la place du contenu */
  display: inline-block; 
  box-shadow: 0px 3px 5px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 6px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 18px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)) !important;}

/* Pour le texte des items s’il reste désaligné */
.custom-snackbar .v-list.custom-list .v-list-item-title {
  text-align: center;  /* Assure que les titres soient centrés */
  /* color: #333; si vous voulez une couleur de texte spécifique */
}

.custom-snackbar.v-snackbar,
.custom-snackbar .v-snackbar__wrapper {
  background-color: transparent !important; 
  box-shadow: none !important;       /* Pour retirer toute ombre */
  border: none;                      /* Éventuellement */
}
.custom-snackbar .v-snackbar__wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.custom-snackbar .v-snackbar__content {
  min-width: 200px;
  max-width: 600px;     /* Optionnel, pour limiter la largeur max */
  width: auto;          /* Laisse l'élément grandir selon le texte */
  text-align: center;   /* Centre le texte à l'intérieur */
  border-radius: 10px;  /* L’arrondi que vous vouliez */
}
</style>