export const getInitialState = () => ({
  user: {
    appliances: [],
    details: {},
    likedApartments: [],
    didFollowTutorial: false,
    askedToGiveFeedback: false,
    totalApartmentsSeen: 0,
    followedTutorial: false,
  },
  swipe: {
    compatibleApartments: [],
    compatibleFullApartments: [],
    lastSeenApartments: [],
    lastActions: []
  },
  snackbar: {
    showSnackbar: false,
    snackbarData: {
      text: [],
      color: ''
    }
  },
  session: {
    loggedIn: false,
    accessToken: '',
    refreshToken: '',
  },
  files: {
    files: {
      avatar: [],
      id: [],
      scolarshipCert: [],
      scolarshipCertN: [],
      warrantId: [],
      taxDoc: [],
      cdiSpe1: [],
      cdiSpe2: [],
      unemployedSpe: [],
      independantSpe: [],
      retiredSpe: [],
      nonPhysicalWarrantDoc: [],
      quittanceLoyer: [],
      justifDomicile: []
    },
    filesToDelete: []
  },
  audit: {
    total: 0,
    warrantIsKnown: false,
    noValueAtZero: false,
    warrantAudit: {},
    searchAudit: {}
  }
})